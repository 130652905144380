import React from 'react'
import { Typography, Container, Grid, Divider } from '@material-ui/core'
import { Layout } from '../components/layout'
import InstagramEmbed from 'react-instagram-embed'
import { graphql } from 'gatsby'
import ReactPlayer from 'react-player'

interface Props {
  data: any
}

const Videography: React.FC<Props> = ({ data }) => {
  const { edges } = data.allContentfulVideography
  console.log('videography edges', edges)
  const nonInstagramEdges = edges.filter(
    (n: any) =>
      n.node.isInstagramLink === false && n.node.isInstagramLink !== null
  )
  const instagramEdges = edges.filter(
    (n: any) =>
      n.node.isInstagramLink === true || n.node.isInstagramLink === null
  )
  console.log('nonInstagramEdges', nonInstagramEdges)
  console.log('instagramEdges', instagramEdges)
  return (
    <Layout>
      <Container>
        <Grid container justify={'center'} style={{ marginBottom: '32px' }}>
          <Grid alignContent={'center'} item md={12}>
            <Typography
              gutterBottom
              variant={'h1'}
              style={{ textAlign: 'center', margin: '32px' }}
            >
              Videography
            </Typography>
            <Divider variant={'middle'} />
          </Grid>
        </Grid>
        <Grid
          container
          justify={'center'}
          spacing={5}
          style={{ marginBottom: '64px' }}
        >
          {nonInstagramEdges.length > 0 &&
            nonInstagramEdges.map((e: any) => (
              <Grid item md={4} style={{ textAlign: '-webkit-center' }}>
                <Typography
                  align={'center'}
                  variant={'h3'}
                  style={{ marginBottom: '8px' }}
                >
                  {e.node.title}
                </Typography>
                <ReactPlayer
                  controls
                  key={e.node.id}
                  url={e.node.url.url}
                  width={'100%'}
                  height={'100%'}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container justify={'center'} spacing={5}>
          {instagramEdges.length > 0 &&
            instagramEdges.map((e: any) => (
              <Grid item md={4} style={{ textAlign: '-webkit-center' }}>
                <Typography
                  align={'center'}
                  variant={'h3'}
                  style={{ marginBottom: '8px' }}
                >
                  {e.node.title}
                </Typography>
                <InstagramEmbed
                  url={e.node.url.url}
                  hideCaption
                  maxWidth={320}
                  containerTagName="div"
                  protocol=""
                  injectScript
                  onLoading={() => {}}
                  onSuccess={() => {}}
                  onAfterRender={() => {}}
                  onFailure={() => {}}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Videography

export const query = graphql`
  query VideographyQuery {
    allContentfulVideography {
      edges {
        node {
          isInstagramLink
          title
          id
          url {
            url
          }
        }
      }
    }
  }
`
